import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";



Font.register({
  family: "Barlow Condensed",
  fonts: [
    { src: "/fonts/BarlowCondensed/BarlowCondensed-Light.ttf", fontWeight: 300 },
    { src: "/fonts/BarlowCondensed/BarlowCondensed-Regular.ttf", fontWeight: 400 },
    { src: "/fonts/BarlowCondensed/BarlowCondensed-Medium.ttf", fontWeight: 500 },
    { src: "/fonts/BarlowCondensed/BarlowCondensed-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/BarlowCondensed/BarlowCondensed-Bold.ttf", fontWeight: 700 },
  ],
});

Font.register({
  family: "Crimson Pro",
  fonts: [
    { src: "/fonts/CrimsonPro/CrimsonPro.ttf", fontWeight: 400 }
  ],
});

Font.register({
  family: "HostGrotesk",
  fonts: [
    { src: "/fonts/HostGrotesk/HostGrotesk-Light.ttf", fontWeight: 300 },
    { src: "/fonts/HostGrotesk/HostGrotesk-Regular.ttf", fontWeight: 400 },
    { src: "/fonts/HostGrotesk/HostGrotesk-Medium.ttf", fontWeight: 500 },
    { src: "/fonts/HostGrotesk/HostGrotesk-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/HostGrotesk/HostGrotesk-Bold.ttf", fontWeight: 700 },
  ],
});

Font.register({
  family: "Montserrat",
  fonts: [
    { src: "/fonts/Montserrat/Montserrat-Light.ttf", fontWeight: 300 },
    { src: "/fonts/Montserrat/Montserrat-Regular.ttf", fontWeight: 400 },
    { src: "/fonts/Montserrat/Montserrat-Medium.ttf", fontWeight: 500 },
    { src: "/fonts/Montserrat/Montserrat-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/Montserrat/Montserrat-Bold.ttf", fontWeight: 700 },
  ],
});

Font.register({
  family: "Noto Serif",
  fonts: [
    { src: "/fonts/NotoSerif/NotoSerif-Light.ttf", fontWeight: 300 },
    { src: "/fonts/NotoSerif/NotoSerif-Regular.ttf", fontWeight: 400 },
    { src: "/fonts/NotoSerif/NotoSerif-Medium.ttf", fontWeight: 500 },
    { src: "/fonts/NotoSerif/NotoSerif-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/NotoSerif/NotoSerif-Bold.ttf", fontWeight: 700 },
  ],
});

Font.register({
  family: "Poppins",
  fonts: [
    { src: "/fonts/Poppins/Poppins-Light.ttf", fontWeight: 300 },
    { src: "/fonts/Poppins/Poppins-Regular.ttf", fontWeight: 400 },
    { src: "/fonts/Poppins/Poppins-Medium.ttf", fontWeight: 500 },
    { src: "/fonts/Poppins/Poppins-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/Poppins/Poppins-Bold.ttf", fontWeight: 700 },
  ],
});

Font.register({
  family: "Righteous",
  fonts: [
    { src: "/fonts/Righteous/Righteous.ttf", fontWeight: 400 },
  ],
});


Font.register({
  family: "Satisfy",
  fonts: [
    { src: "/fonts/Satisfy/Satisfy-Regular.ttf", fontWeight: 400 },
  ],
});

const MenuToPDF = ({
  categories,
  itemsByCategory,
  mainLanguage,
  selectedLanguage,
  settings,
  showDescriptions,
  showAllergens,
  showItemSeparator,
  showTags,
  getTagsNames,
  getAllergenNames,
  printSettings,
}) => {
  
  
  const pageSizes = {
    A3: 297,
    A4: 210,
    A5: 148,
  };
  
  function calculateItemColumnWidth(printSettings) {
    const pageWidth = pageSizes[printSettings.pageSize]; // Get page width based on pageSize
    const { left, right } = printSettings.margins;
  
    // Define a fixed column gap in mm
    const columnGap = left / 4; // For example, 1/4 of the left margin
  
    // Calculate usable width in mm (excluding margins and column gap)
    const usableWidth = pageWidth - left - right - columnGap;
  
    // Calculate the width of one column in mm
    const itemColumnWidth = usableWidth / 2;
  
    return {
      itemColumnWidth: `${itemColumnWidth}mm`,
      columnGap: `${columnGap}mm`
    };
  }
  
  const { itemColumnWidth, columnGap } = calculateItemColumnWidth(printSettings);
    
  
    const styles = StyleSheet.create({
    page: {
      paddingTop: `${printSettings.margins.top}mm`,
      paddingBottom: `${printSettings.margins.bottom}mm`,
      paddingLeft: `${printSettings.margins.left}mm`,
      paddingRight: `${printSettings.margins.right}mm`,
      lineHeight: 1,
    },
    printMenu: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10mm",
    },
    menuCategory: {
      breakInside: "avoid",
    },
    categoryHeader: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
      alignItems: "baseline",
    },
    categoryTitle: {
      textTransform: "uppercase",
      margin: "0 0 3.5mm",
      fontSize: `${printSettings.fontStyles.categoryTitle.fontSize || 14}pt`,
      color: `${printSettings.fontStyles.categoryTitle.color || "#000000"}`,
      fontFamily: `${printSettings.fontStyles.categoryTitle.fontFamily || "Poppins"}`, 
      fontWeight: `${printSettings.fontStyles.categoryTitle.fontWeight || 600 }`, 
    },
    categoryTagline: {
      fontSize: `${printSettings.fontStyles.categoryTagline.fontSize || 11}pt`,
      color: `${printSettings.fontStyles.categoryTagline.color || "#000000"}`,
      fontFamily: `${printSettings.fontStyles.categoryTagline.fontFamily || "Satisfy"}`, 
      fontWeight: `${printSettings.fontStyles.categoryTagline.fontWeight || 400 }`, 
    },
    categoryPrice: {
      fontSize: `${printSettings.fontStyles.categoryPrice.fontSize || 14}pt`,
      color: `${printSettings.fontStyles.categoryPrice.color || "#000000"}`,
      fontFamily: `${printSettings.fontStyles.categoryPrice.fontFamily || "Poppins"}`, 
      fontWeight: `${printSettings.fontStyles.categoryPrice.fontWeight || 600 }`, 
    },
    itemGrid: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      columnGap: columnGap,
    },
    item: {
      display: "flex",
      flexDirection: "column",
      borderBottom: showItemSeparator ? "1px solid #F2F2F2" : "none",
      width: "100%",
      padding: "4pt 0 5pt",
      paddingLeft: "3mm",
      gap: "1mm",
      borderLeft: `1px solid ${settings.brandColor}`,
    },
    itemColumn: {
      width: itemColumnWidth,
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      borderBottom: showItemSeparator ? "1px solid #F2F2F2" : "none",
      padding: "4pt 0 5pt",
      gap: "1mm",
    },
    itemHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      borderWidth: 0,
      gap: "4px",
    },
    itemNameRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1",
      gap: "4px",
      alignItems: "baseline",
    },
    itemName: {
      color: `${printSettings.fontStyles.itemName.color || "#000000"}`,
      fontFamily: `${printSettings.fontStyles.itemName.fontFamily || "Barlow Condensed"}`,
      fontSize: `${printSettings.fontStyles.itemName.fontSize || 11}pt`,
      fontWeight: `${printSettings.fontStyles.itemName.fontWeight || 500 }`,
      maxWidth: "85%",
      textTransform: "uppercase",
    },
    itemDescription: {
      color: `${printSettings.fontStyles.itemDescription.color || "#667085"}`,
      fontSize: `${printSettings.fontStyles.itemDescription.fontSize || 9}pt`,
      fontFamily: `${printSettings.fontStyles.itemDescription.fontFamily || "Poppins"}`,
      fontWeight: `${printSettings.fontStyles.itemDescription.fontWeight || 400 }`,
      maxWidth: `${printSettings.fontStyles.itemDescription.width || 85 }%`,
      lineHeight: "1.2",
    },
    itemTags: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "1px",
      transform: "translateY(1px)",
    },
    itemTag: {
      fontSize: "6pt",
      color: "black",
      border: "1pt solid #eee",
      borderRadius: "16px",
      padding: "3px 5px 2px",
      whiteSpace: "nowrap",
    },
    itemPrice: {
      color: `${printSettings.fontStyles.itemPrice.color || settings.brandColor}`,
      fontSize: `${printSettings.fontStyles.itemPrice.fontSize || 11}pt`,
      fontWeight: `${printSettings.fontStyles.itemPrice.fontWeight || 500 }`, 
      fontFamily: `${printSettings.fontStyles.itemPrice.fontFamily || "Barlow Condensed"}`,
    },
    variationSeparator: {
      margin: "0 1mm",
      height: "100%",
      backgroundColor: "#eee",
      width: "1px",
    },
    itemVariations: {
      display: "flex",
      flexDirection: "row",
    },
    itemVariation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      gap: "2px",
    },
    itemVariationName: {
      color: `${printSettings.fontStyles.itemVariations.color || "black"}`,
      fontSize: `${printSettings.fontStyles.itemVariations.fontSize || 9}pt`,
      fontWeight: `${printSettings.fontStyles.itemVariations.fontWeight || 500 }`, 
      fontFamily: `${printSettings.fontStyles.itemVariations.fontFamily || "Barlow Condensed"}`,
      textTransform: "uppercase",
    },
    itemVariationPrice: {
      color: `${printSettings.fontStyles.itemVariationsPrice.color || settings.brandColor}`,
      fontSize: `${printSettings.fontStyles.itemVariationsPrice.fontSize || 9}pt`,
      fontWeight: `${printSettings.fontStyles.itemVariationsPrice.fontWeight || 500 }`, 
      fontFamily: `${printSettings.fontStyles.itemVariationsPrice.fontFamily || "Barlow Condensed"}`,
    },
    itemAllergens: {
      fontSize: "6pt",
      fontWeight: "400",
      color: "#555",
      textTransform: "uppercase",
    },
  });

  return (
      <Document>
        <Page size={printSettings.pageSize} style={styles.page} wrap>
          <View style={styles.printMenu}>
            {categories
              .filter((cat) => cat.hide !== true)
              .filter((cat) => cat.id !== "uncategorized")
              .filter(
                (cat) =>
                  itemsByCategory[cat.id] &&
                  itemsByCategory[cat.id].some(
                    (item) =>
                      item.display &&
                      item.display.includes("menu") &&
                      item.availability
                  )
              )
              .map((category, index) => {
                const isFirstCategory = index === 0;
  
                return (
                  <React.Fragment key={category.id}>
                    {!isFirstCategory && category.pageBreak === "before" && (
                      <View style={styles.fillerSpace} wrap={false} />
                    )}
  
                    <View
                      break={category.pageBreak === "before" && !isFirstCategory}
                      style={styles.menuCategory}>
                      <View style={styles.categoryHeader}>
                        <Text style={styles.categoryTitle}>
                          {category[`name_${selectedLanguage}`] ||
                            category[`name_${mainLanguage}`] ||
                            ""}
                        </Text>
  
                        {category.formula && category.formulaPrice && (
                          <Text style={styles.categoryPrice}>
                            {category.formulaPrice.toLocaleString("fr-FR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            €
                          </Text>
                        )}
  
                        {category[`description_${mainLanguage}`] && (
                          <Text style={styles.categoryTagline}>
                            {category[`description_${selectedLanguage}`] ||
                              category[`description_${mainLanguage}`] ||
                              ""}
                          </Text>
                        )}
                      </View>
  
                      <View style={styles.itemGrid}>
                        {itemsByCategory[category.id].map((item) => (
                          item.display &&
                          item.display.includes("menu") &&
                          item.availability !== false && (
                            <View
                              style={{
                                ...styles.item,
                                ...(category.columns === "2" && styles.itemColumn),
                              }}
                              wrap={false}
                              key={item.id}>
  
                              <View style={styles.itemHeader}>
                                <View style={styles.itemNameRow}>
                                  {item[`name_${mainLanguage}`] && (
                                    <Text style={styles.itemName}>
                                      {item[`name_${selectedLanguage}`] ||
                                        item[`name_${mainLanguage}`] ||
                                        ""}
                                    </Text>
                                  )}

{showTags &&  
                                  <View style={styles.itemTags}>
                                    {Array.isArray(item.tags) &&
                                      item.tags.length > 0 &&
                                      item.tags.map((tag) => (
                                        <Text style={styles.itemTag} key={tag.id}>
                                          {getTagsNames([tag])}
                                        </Text>
                                      ))}
                                    {item[`note_${mainLanguage}`] && (
                                      <Text style={styles.itemTag}>
                                        {item[`note_${selectedLanguage}`] ||
                                          item[`note_${mainLanguage}`]}
                                      </Text>
                                    )}
                                  </View>}
                                </View>
  
                                {!category.formula && (
                                  <View style={styles.itemVariations}>
                                    {Array.isArray(item.variations) &&
                                    item.variations.length > 0 ? (
                                      item.variations.map((variation, index) => (
                                        <React.Fragment key={variation.id}>
                                          {index > 0 && (
                                            <View
                                              key={`${variation.id}-separator`}
                                              style={styles.variationSeparator}
                                            />
                                          )}
                                          <View style={styles.itemVariation}>
                                            <Text style={styles.itemVariationName}>
                                              {variation.name}
                                            </Text>
                                            <Text style={styles.itemVariationPrice}>
                                              {variation.price}€
                                            </Text>
                                          </View>
                                        </React.Fragment>
                                      ))
                                    ) : (
                                      <Text style={styles.itemPrice}>
                                        {item.price}€
                                      </Text>
                                    )}
                                  </View>
                                )}
                              </View>
  
                              {showDescriptions &&
                                item[`description_${mainLanguage}`] && (
                                  <View>
                                    <Text style={styles.itemDescription}>
                                      {item[`description_${selectedLanguage}`] ||
                                        item[`description_${mainLanguage}`] ||
                                        ""}
                                    </Text>
                                  </View>
                                )}
  
                              {showAllergens &&
                                Array.isArray(item.allergenes) && (
                                  <View>
                                    {getAllergenNames(item.allergenes) && (
                                      <Text style={styles.itemAllergens}>
                                        All.: {getAllergenNames(item.allergenes)}
                                      </Text>
                                    )}
                                  </View>
                                )}
                            </View>
                          )
                        ))}
                      </View>
                    </View>
                  </React.Fragment>
                );
              })}
          </View>
        </Page>
      </Document>
  );
};

export default MenuToPDF;
